.loader_dash {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.bar_dash {
  width: 7px;
  height: 18px;
  margin: 0 9px;
  border-radius: 10px;
  animation: loading_5192 1s ease-in-out infinite;
  background-color: rgb(0, 98, 255);
}

.bar_dash:nth-child(1) {
  animation-delay: 0.01s;
}

.bar_dash:nth-child(2) {
  animation-delay: 0.09s;
}

.bar_dash:nth-child(3) {
  animation-delay: 0.19s;
}

.bar_dash:nth-child(4) {
  animation-delay: 0.29s;
}

@keyframes loading_5192 {
  0% {
    transform: scale(1);
  }

  20% {
    transform: scale(1, 2.5);
  }

  40% {
    transform: scale(1);
  }
}
