.date {
  border: none;
  font-size: 6px;
}

@media screen and (max-width: 600px) {
  .popup {
    width: 300px;
  }
}
