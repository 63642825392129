.layout_padding {
  padding: 90px 0;
}

.layout_padding2 {
  padding: 75px 0;
}

.layout_padding2-top {
  padding-top: 75px;
}

.layout_padding2-bottom {
  padding-bottom: 75px;
}

.layout_padding-top {
  padding-top: 90px;
}

.layout_padding_bottom {
  padding-bottom: 90px;
}

.heading_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.heading_container h2 {
  position: relative;
  font-weight: bold;
}

.heading_container.heading_center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

a,
a:hover,
a:focus {
  text-decoration: none;
}

a:hover,
a:focus {
  color: initial;
}

.btn,
.btn:focus {
  outline: none !important;
  -webkit-box-shadow: none;
  box-shadow: none;
}

/*header section*/
.hero_area {
  position: relative;
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.bg_box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
}

.bg_box img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.bg_box::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(21, 82, 99, 0.65)),
    to(rgba(21, 82, 99, 0.55))
  );
  background: linear-gradient(
    to right,
    rgba(21, 82, 99, 0.65),
    rgba(21, 82, 99, 0.55)
  );
}

.sub_page .hero_area {
  min-height: auto;
}

.sub_page .header_section {
  margin-top: 0;
  -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15);
}

.header_section {
  padding: 10px 0;
}

.header_section .container-fluid {
  padding-right: 25px;
  padding-left: 25px;
}

.navbar_brand span {
  color: #ffffff;
  font-size: 24px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: bold;
}

.custom_nav-container {
  padding: 0;
}

.custom_nav-container .navbar-nav {
  margin-left: auto;
}

.custom_nav-container .navbar-nav .nav-item .nav-link {
  padding: 7px 20px;
  color: #ffffff;
  text-align: center;
  text-transform: uppercase;
}

.custom_nav-container .navbar-nav .nav-item.active .nav-link {
  background-color: #ff6f3c;
}

.custom_nav-container .nav_search-btn {
  width: 35px;
  height: 35px;
  padding: 0;
  border: none;
  color: #ffffff;
}

.custom_nav-container .nav_search-btn:hover {
  color: #ff6f3c;
}

.custom_nav-container .navbar-toggler {
  outline: none;
}

.custom_nav-container .navbar-toggler {
  padding: 0;
  width: 37px;
  height: 42px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.custom_nav-container .navbar-toggler span {
  display: block;
  width: 35px;
  height: 4px;
  background-color: #ffffff;
  margin: 7px 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  position: relative;
  border-radius: 5px;
  transition: all 0.3s;
}

.custom_nav-container .navbar-toggler span::before,
.custom_nav-container .navbar-toggler span::after {
  content: "";
  position: absolute;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #ffffff;
  top: -10px;
  border-radius: 5px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.custom_nav-container .navbar-toggler span::after {
  top: 10px;
}

.custom_nav-container .navbar-toggler[aria-expanded="true"] {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}

.custom_nav-container .navbar-toggler[aria-expanded="true"] span {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.custom_nav-container .navbar-toggler[aria-expanded="true"] span::before,
.custom_nav-container .navbar-toggler[aria-expanded="true"] span::after {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  top: 0;
}

.custom_nav-container .navbar-toggler[aria-expanded="true"] .s-1 {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  margin: 0;
  margin-bottom: -4px;
}

.custom_nav-container .navbar-toggler[aria-expanded="true"] .s-2 {
  display: none;
}

.custom_nav-container .navbar-toggler[aria-expanded="true"] .s-3 {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  margin: 0;
  margin-top: -4px;
}

.custom_nav-container .navbar-toggler[aria-expanded="false"] .s-1,
.custom_nav-container .navbar-toggler[aria-expanded="false"] .s-2,
.custom_nav-container .navbar-toggler[aria-expanded="false"] .s-3 {
  -webkit-transform: none;
  transform: none;
}

.quote_btn-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.quote_btn-container a {
  color: #000000;
  text-transform: uppercase;
}

.quote_btn-container a span {
  margin-left: 5px;
}

.quote_btn-container a:hover {
  color: #155263;
}

.quote_btn-container .quote_btn {
  display: inline-block;
  padding: 5px 25px;
  background-color: #ff6f3c;
  color: #ffffff;
  border-radius: 5px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border: none;
}

.quote_btn-container .quote_btn:hover {
  background-color: #ef3e00;
}

/*end header section*/
/* slider section */
.slider_section {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 90px 0 145px 0;
  position: relative;
}

.slider_section .row {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* .slider_section .find_form_container {
  
} */

.slider_section .find_form_container .form_group {
  margin: 15px;
  position: relative;
  -webkit-box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.1);
}

.slider_section .find_form_container .input_group_text {
  width: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #f7f8f3;
  border: none;
}

.slider_section .find_form_container label {
  color: #fff;
}

.slider_section .find_form_container .form_control {
  height: 50px;
  outline: none;
  border: none;
  position: relative;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: #f7f8f3;
}

.slider_section .find_form_container .form_control::-webkit-input-placeholder {
  font-size: 14px;
}

.slider_section .find_form_container .form_control:-ms-input-placeholder {
  font-size: 14px;
}

.slider_section .find_form_container .form_control::-ms-input-placeholder {
  font-size: 14px;
}

.slider_section .find_form_container .form_control::placeholder {
  font-size: 14px;
}

.slider_section .find_form_container .form_control.nice-select {
  line-height: 39px;
}

.slider_section .find_form_container .nice_select ul {
  width: 100%;
  padding: 0;
}

.slider_section .find_form_container .nice_select::after {
  right: 15px;
  border: none;
  border-top: 5px solid #ccc;
  border-right: 5px solid transparent;
  border-bottom: 0;
  border-left: 5px solid transparent;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.slider_section .find_form_container .nice-select.open::after {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.slider_section .find_form_container .btn_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 25px;
}

.slider_section .find_form_container .btn_box button {
  display: inline-block;
  padding: 12px 50px;
  background-color: #ff6f3c;
  color: #ffffff;
  border-radius: 45x;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border: none;
}

.slider_section .find_form_container .btn_box button:hover {
  background-color: #ef3e00;
}

.slider_section #customCarousel1 {
  width: 100%;
  position: unset;
}

.slider_section .detail_box {
  color: #adadad;
  text-align: center;
}

.slider_section .detail_box h1 {
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 10px;
  color: #ffffff;
}

.slider_section .detail_box p {
  color: #ffffff;
  font-size: 15px;
  font-weight: 300;
}

.slider_section .detail_box .btn_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 -5px;
  margin-top: 30px;
}

.slider_section .detail_box .btn_box a {
  margin: 5px;
  text-align: center;
  width: 165px;
}

.slider_section .detail_box .btn_box .btn1 {
  display: inline-block;
  padding: 10px 15px;
  background-color: #ffffff;
  color: #000000;
  border-radius: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border: none;
}

.slider_section .detail_box .btn_box .btn1:hover {
  background-color: #d9d9d9;
}

.slider_section .detail_box .btn_box .btn2 {
  display: inline-block;
  padding: 10px 15px;
  background-color: #ff6f3c;
  color: #ffffff;
  border-radius: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border: none;
}

.slider_section .detail_box .btn_box .btn2:hover {
  background-color: #ef3e00;
}

.slider_section .img_box img {
  width: 100%;
}

.slider_section .carousel_indicators {
  position: unset;
  margin: 0;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 45px;
}

.slider_section .carousel_indicators li {
  background-color: #ffffff;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  opacity: 1;
  border: 1px solid #ffffff;
  text-indent: unset;
  background-clip: unset;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.slider_section .carousel_indicators li.active {
  width: 20px;
  height: 20px;
  background-color: transparent;
}

.about_section .heading_container {
  margin-bottom: 30px;
}

.about_section .row {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.about_section .img_box img {
  max-width: 100%;
}

.about_section .detail_box h3 {
  font-weight: bold;
}

.about_section .detail_box p {
  margin-top: 15px;
}

.about_section .detail_box button {
  display: inline-block;
  padding: 10px 45px;
  background-color: #ff6f3c;
  color: #ffffff;
  border-radius: 0px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border: none;
  margin-top: 15px;
}

.about_section .detail_box button:hover {
  background-color: #ef3e00;
}

.why_section .box {
  height: 350px;
  border-radius: 15px;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-top: 45px;
  background: #155263;
  color: #ffffff;
  padding: 25px;
}

.why_section .box .img_box {
  width: 105px;
  height: 105px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #ffffff;
  border-radius: 100%;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.why_section .box .img_box img {
  width: 55px;
  height: 55px;
}

.why_section .box .detail_box {
  margin-top: 20px;
}

.why_section .box .detail_box h4 {
  font-weight: bold;
  font-size: 22px;
}

.why_section .box .detail_box p {
  margin-bottom: 0;
}

.why_section .box.active .img_box,
.why_section .box:hover .img_box {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

.pricing_section {
  position: relative;
}

.pricing_section .heading_container {
  color: #ffffff;
}

.pricing_section .box {
  background: #ffffff;
  text-align: center;
  padding: 25px;
  border-radius: 15px;
  margin-top: 75px;
  position: relative;
  overflow: hidden;
}

.pricing_section .box * {
  position: relative;
  z-index: 1;
}

.pricing_section .box::before {
  content: "";
  position: absolute;
  top: -100px;
  left: 50%;
  width: 400px;
  height: 400px;
  /* background: #ff6f3c; */
  background: #3b71ca;
  border-radius: 100%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.pricing_section .box .price {
  font-size: 2rem;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 65px;
}

.pricing_section .box a {
  color: #155263;
}

.pricing_section .box.box_center {
  margin-top: 45px;
}

.client_section .heading_container {
  margin-bottom: 25px;
}

.client_section .box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 15px;
}

.client_section .box .detail_box {
  margin-bottom: 15px;
  position: relative;
}

.client_section .box .detail_box p {
  position: relative;
  z-index: 1;
}

.client_section .box .detail_box::before,
.client_section .box .detail_box::after {
  font-family: FontAwesome;
  display: inline-block;
  padding-right: 6px;
  vertical-align: middle;
  position: absolute;
  font-size: 68px;
  color: #e0e0e0;
}

.client_section .box .detail_box::before {
  content: "\f10d";
  top: -25px;
  left: -10px;
}

.client_section .box .detail_box::after {
  content: "\f10e";
  bottom: -25px;
  right: 0;
}

.client_section .box .client_id {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.client_section .box .client_id .img_box {
  position: relative;
  width: 100px;
  height: 100px;
}

.client_section .box .client_id .img_box img {
  width: 100%;
  border-radius: 100%;
  border: 5px solid #155263;
}

.client_section .box .client_id .name {
  margin-left: 15px;
}

.client_section .box .client_id .name h6 {
  color: #23242a;
  font-size: 18px;
  margin-bottom: 0;
}

.client_section .box .client_id .name p {
  margin-bottom: 0;
}

.client_section .owl_carousel .owl_nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 45px;
  padding: 0 15px;
}

.client_section .owl_carousel .owl_nav .owl_prev,
.client_section .owl_carousel .owl_nav .owl_next {
  width: 45px;
  height: 45px;
  background-color: #ff6f3c;
  color: #ffffff;
  outline: none;
  bottom: 0px;
  font-size: 24px;
  margin-right: 10px;
}

.info_section {
  background-color: #fbfeff;
  color: #ffffff;
  padding-top: 60px;
  padding-bottom: 25px;
}

.info_section .info_col {
  margin-bottom: 35px;
}

.info_section .contact_nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.info_section .contact_nav a {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #ffffff;
  margin-bottom: 10px;
}

.info_section .contact_nav a i {
  font-size: 22px;
  margin-right: 10px;
}

.info_section .contact_nav a:hover {
  color: #ff6f3c;
}

.info_section h4 {
  text-transform: uppercase;
  position: relative;
  margin-bottom: 20px;
  color: #ffffff;
  font-weight: 600;
}

.info_section .social_box {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 25px;
}

.info_section .social_box a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  border: 1px solid #ffffff;
  color: #ffffff;
  font-size: 18px;
  margin-right: 10px;
}

.info_section .social_box a:hover {
  color: #ff6f3c;
  border-color: #ff6f3c;
}

.info_section .info_form input {
  width: 100%;
  border: none;
  height: 45px;
  margin-bottom: 10px;
  padding-left: 25px;
  background-color: #eaeaea;
  outline: none;
  color: #101010;
}

.info_section .info_form button {
  width: 100%;
  display: inline-block;
  padding: 10px 45px;
  /* background-color: #ff6f3c; */
  color: #ffffff;
  border-radius: 0px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  border: none;
}

.info_section .info_form button:hover {
  background-color: #ef3e00;
}

/* footer section*/
.footer_section {
  position: relative;
  background-color: #ffffff;
  text-align: center;
}

.footer_section p {
  color: #155263;
  padding: 25px 0;
  margin: 0;
}

.footer_section p a {
  color: inherit;
}
/*# sourceMappingURL=style.css.map */
