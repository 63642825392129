.hr {
  border: none;
  height: 2px;
  width: 35%;
  background-color: rgb(198, 192, 192);
  border-radius: 10%;
  opacity: 1 !important;
}



@media (max-width: 767px) {
  .border-sm-start-none {
    border-left: none !important;
  }
}

@media screen and (max-width: 992px) {
  .hide992 {
    display: none;
  }
}