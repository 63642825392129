.homeContainer {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  width: 100%;
}

.homeTitle {
  width: 100%;
  max-width: 1024px;
}