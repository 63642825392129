@keyframes open {
    0% {
        background-position: 166% -25%, 220% -69%;
    }

    100% {
        background-position: 120% -5%, 200% -40%;
    }
}

input[type="file"] {
    display: none;
}

.custom-file-upload {
    border-radius: 50%;
    display: inline-block;
    position: relative;
    padding: 6px;
    cursor: pointer;
    background: linear-gradient(270deg, #3fa1a9, #79f1a4);
    margin-bottom: 25px;
}

.img-wrap {
    position: relative;
    width: 150px;
    height: 150px;
    overflow: hidden;
    border-radius: 50%;
}

.img-upload:before {
    content: "\f093";
    font-size: 70px;
    position: absolute;
    padding-top: 60px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #63d3a6;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    opacity: 0;
    transition: 0.5s ease;
    background-color: #fff;
}

.img-upload:hover:before {
    opacity: 1;
}

img {
    /* width: auto; */
    height: 100%;
}