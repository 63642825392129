/* .lsItem {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 10px;
}
.lsItem > input {
  height: 30px;
  border: none;
  padding: 5px;
} */

.lsItem > span {
  height: 30px;
  padding: 5px;
  background-color: white;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.lsItem {
  margin-bottom: 20px;
}