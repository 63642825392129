.listContainer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.listWrapper {
  width: 100%;
  /* max-width: 90%; */
  gap: 20px;
  position: sticky;
  top: 6vw;
}

.listSearch {
  background-color: #55a7f1;
  padding: 10px;
  border-radius: 10px;

  height: max-content;
}

.lsTitle {
  font-size: 20px;
  color: white;
  margin-bottom: 10px;
}

.filterTitle {
  font-size: 20px;
  /* color: white; */
  margin-bottom: 8px;
  /* margin-top: 8px; */
  padding-top: 8px;
  padding-bottom: 2px;
  padding-left: 10px;
}

.lsItem {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 10px;
}

.filterItem {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: 10px;
  margin-left: 15px;
}

.filterItem label {
  font-size: 16px;
}

.lsItem > input {
  height: 30px;
  border: none;
  padding: 5px;
}

.lsItem > span {
  height: 30px;
  padding: 5px;
  background-color: white;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.lsOptions {
  padding: 10px;
}

.lsOptionItem {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  color: white;
  font-size: 12px;
}

.lsOptionInput {
  width: 50px;
}

.listSearch > button {
  width: 100%;
  font-weight: 500;
  cursor: pointer;
}

.listResult {
  flex: 7;
}

/* dropdowncss */
.headerSearchText {
  color: lightgray;
  cursor: pointer;
}
.options {
  /* z-index: 10; */
  position: absolute;
  top: 50px;
  background-color: white;
  color: gray;
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 10px -5px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 10px -5px rgba(0, 0, 0, 0.4);
}
.optionItem {
  width: 370px;
  /* width:max-content; */
  display: flex;
  /* justify-content: space-between; */
  /* align-items: center; */
  margin: 10px;
}
.optionCounterButton {
  /* margin-left: 10px; */
  width: 5px !important;
  height: 5px !important;
  border: 1px solid #0071c2 !important;
  color: #0071c2 !important;
  cursor: pointer;
  border-radius: 5px !important;
  background-color: white !important;
}

.optionCounterButton:disabled {
  cursor: not-allowed;
}

.searchcontainer {
  /* background-color: #ffffff; */
  padding: 10px;
  border-radius: 8px;
}

.listSearch {
  /* background-color: #ffffff; */
  padding: 20px;
  color: #ffffff;
}

.lsTitle {
  font-size: 24px;
  margin-bottom: 20px;
}

.lsItem {
  margin-bottom: 20px;
}

.lsLabel {
  color: white;
  font-size: 16px;
  margin-bottom: 5px;
}

.lsDate {
  font-size: 15px;
  cursor: pointer;
  color: #fff;
  text-decoration: underline;
}

.lsOptions {
  margin-top: 10px;
}

.lsOptionItem {
  margin-bottom: 10px;
}

.lsOptionText {
  font-size: 14px;
  color: #fff;
}

.lsOptionItem input[type="number"] {
  margin-left: 10px;
  padding: 5px;
  width: 60px;
  border: none;
  border-radius: 4px;
  background-color: #f5f5f5;
  color: #333;
}

.btn-primary {
  font-size: 16px;
  padding: 8px 20px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}



@media screen and (max-width: 1024px) {
  .listWrapper {
    max-width: 100%;
  }
}

@media screen and (max-width: 992px) {
  .listSearch {
    display: none;
  }

  .sideSection {
    display: none;
  }
}
