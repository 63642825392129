@import url("https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@100&family=Lato:wght@300&family=Montserrat:wght@300&family=Open+Sans:wght@300&family=Playfair+Display+SC&family=Smooch&family=Ubuntu:wght@300&display=swap");

.custom {
  height: 400px;
  font-family: "playfair display sc", serif;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("./images/modal-bg.png");
  /* background-color: rgb(178, 194, 214); */
}

.custom_input {
  display: block;
  outline: none;
  border: none;
  height: 2rem;
  font-size: 16px;
  margin-bottom: 10px;
  border-bottom: 1px solid #333;
  width: 100%;
  padding: 0.5rem;
}

.custom_input:focus {
  border-bottom: 1px solid #282f35;
  box-shadow: 0 1px 0 0 #282f35;
}

.custom_label {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
}

.custom_button {
  display: inline-block;
  padding: 0.5rem 1rem;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background-color: #000;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.custom_button:hover {
  background-color: #333;
}

.container {
  max-width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.contact_section {
  padding-top: 80px;
  padding-bottom: 80px;
}
.contact_text h2 {
  font-size: 44px;
  color: #19191a;
  margin-bottom: 24px;
}
.contact_text p {
  color: #707079;
}
.contact_text table tbody tr td {
  font-size: 16px;
  color: #19191a;
  line-height: 36px;
  font-weight: 500;
}
.contact_text table tbody tr td.c_o {
  color: #707079;
  width: 75px;
}
.contact_form input {
  width: 100%;
  height: 50px;
  border: 1px solid #e1e1e1;
  font-size: 16px;
  color: #aaaab3;
  padding-left: 25px;
  margin-bottom: 28px;
}
.contact_form input::-webkit-input-placeholder {
  color: #aaaab3;
}
.contact_form input::-moz-placeholder {
  color: #aaaab3;
}
.contact_form input:-ms-input-placeholder {
  color: #aaaab3;
}
.contact_form input::-ms-input-placeholder {
  color: #aaaab3;
}
.contact_form input::placeholder {
  color: #aaaab3;
}
.contact_form textarea {
  width: 100%;
  height: 150px;
  border: 1px solid #e1e1e1;
  font-size: 16px;
  color: #aaaab3;
  padding-left: 25px;
  padding-top: 12px;
  margin-bottom: 33px;
  resize: none;
}
.contact_form textarea::-webkit-input-placeholder {
  color: #aaaab3;
}
.contact_form textarea::-moz-placeholder {
  color: #aaaab3;
}
.contact_form textarea:-ms-input-placeholder {
  color: #aaaab3;
}
.contact_form textarea::-ms-input-placeholder {
  color: #aaaab3;
}
.contact_form textarea::placeholder {
  color: #aaaab3;
}
.contact_form button {
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  color: #ffffff;
  letter-spacing: 2px;
  /* background: #dfa974; */
  border: none;
  padding: 14px 34px 13px;
  display: inline-block;
}
.map {
  height: 470px;
  -webkit-box-shadow: 0px 14px 35px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 14px 35px rgba(0, 0, 0, 0.15);
  margin-top: 75px;
}
.map iframe {
  width: 100%;
}
