.mail {
  width: 100%;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.mailInputContainer>input {
  width: 100%;
  height: 33px;
  padding: 10px;
  padding-right: 10px;
  border: none;
  border-radius: 4px;
}

.mailInputContainer>button {
  margin-top: 8px;
  font-weight: 500;
  cursor: pointer;

}
