.parking-availability {
  font-family: Arial, sans-serif;
}

.parking-availability__card {
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  overflow: hidden;
}

.parking-availability__title {
  font-size: 24px;
  font-weight: bold;
  padding: 20px;
  margin: 0;
  background-color: #333;
  color: #fff;
}

.product-item {
  padding: 20px;
}

.product-header {
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
}

.product-title {
  font-size: 20px;
}

.product-info {
  font-size: 14px;
  color: #666;
}

.product-price {
  font-size: 24px;
  font-weight: bold;
}

.product-check {
  margin-left: 10px;
}

.strong-hour {
  font-weight: bold;
}

.product-label {
  font-size: 14px;
  font-weight: bold;
  color: #666;
}

.divider-top {
  border-top: 1px solid #ccc;
  margin-top: 10px;
  padding-top: 10px;
}

.btn-product {
  background-color: #333;
  color: #fff;
  border-radius: 5px;
  border: none;
  font-size: 16px;
  font-weight: bold;
  padding: 10px;
  margin-top: 20px;
}
.btn-unstyled {
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  padding: 0;
  font: inherit;
  outline: inherit;
  text-decoration: none;
}
