.header_item {
  font-size: 4rem !important;
}

.header_icon {
  font-size: 5rem !important;
  padding: 0.4rem !important;
}

@import url("https://fonts.googleapis.com/css?family=Nunito:400,700");

* {
  transition: all 0.3s ease-out;
}

body {
  font-family: "Nunito", sans-serif;
  font-size: 16px;
}

.wrapper {
  width: 100% !important;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

h3 {
  color: #262626;
  font-size: 17px;
  line-height: 24px;
  font-weight: 700;
  margin-bottom: 4px;
}

p {
  font-size: 17px;
  font-weight: 400;
  line-height: 20px;
  color: #666;
}

p.small {
  font-size: 14px;
}

.go_corner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 36px;
  height: 36px;
  overflow: hidden;
  top: 0;
  right: 0;
  background-color: #00838f;
  border-radius: 0 4px 0 32px;
}

.go_arrow {
  margin-top: -5px;
  margin-right: -5px;
  color: white;
  font-family: courier, sans;
}

.card1 {
  display: block;
  width: 100% !important;
  position: relative;
  background-color: #dfebf6;
  border-radius: 4px;
  padding: 28px 24px 20px 24px;
  margin: 12px;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
}

.card1:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: -16px;
  right: -16px;
  background: #00838f;
  height: 32px;
  width: 32px;
  border-radius: 32px;
  transform: scale(1);
  transform-origin: 50% 50%;
  transition: transform 0.25s ease-out;
}

.card1:hover:before {
  transform: scale(17);
}

.card1:hover p {
  transition: all 0.3s ease-out;
  color: rgba(255, 255, 255, 0.8);
}

.card1:hover h3 {
  transition: all 0.3s ease-out;
  color: #fff;
}

.heading {
  font-family: 'Playfair Display', serif;
  color: #00838f;
}

.date {
  font-family: 'Noto Sans JP', sans-serif;
}

.card1_description {
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 3rem;
  /* color: #00838f; */
}

.red {
  color: #F44336;
}

.green {
  color: #4CAF50;
}

.yellow {
  color: #FFC107;
}

.blue {
  color: #2196F3;
}