.fp {
  width: 100%;
  max-width: 1024px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.fpItem {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.fpImg {
  width: 100%;
  height: 250px;
  object-fit: cover;
}

.fpName {
  color: #333;
  font-weight: bold;
}

.fpCity {
  font-weight: 300;
}

.fpPrice {
  font-weight: 500;
}

.fpRating > button {
  color: white;
  
  font-weight: bold;
}

.fpRating > span {
  font-size: 14px;
}

@media screen and (max-width: 992px) {
  .fpImg {
    height: 200px;
  }
}

@media screen and (max-width: 768px) {
  .fp {
    flex-direction: column;
  }
}
