.featured {
  width: 100%;
  max-width: 1024px !important;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  z-index: 1;
  margin-bottom: 10px;
}

.cursor{
  cursor: pointer;
}

.featuredItem {
  position: relative;
  color: white;
  border-radius: 10px;
  overflow: hidden;
  height: 250px;
  flex: 1;
}

.featuredItems {
  position: relative;
  color: white;
  border-radius: 10px;
  overflow: hidden;
  height: 250px;
  flex: 2;
}

.featuredImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.featuredTitles {
  position: absolute;
  bottom: 20px;
  left: 20px;
}

h1 {
  font-size: 1.5rem;
}

h2 {
  font-size: 1.3rem;
}

.text_shadow {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
}

@media screen and (max-width: 992px) {
  .featuredItem {
    height: 200px;
  }

  .featuredItems {
    height: 200px;
  }
}

@media screen and (max-width: 767px) {
  .featuredItem {
    height: 150px;
  }

  .featuredItems {
    height: 150px;
  }

  h1{
    font-size: 1.2rem;
  }

  h2{
    font-size: 1rem;
  }
  .featuredTitles {
    position: absolute;
    bottom: 16px;
    left: 16px;
  }
}

@media screen and (max-width: 510px) {
  .featuredItem {
    height: 130px;
  }

  .featuredItems {
    height: 130px;
  }

  h1{
    font-size: 1rem;
  }

  h2{
    font-size: 0.8rem;
  }
  .featuredTitles {
    position: absolute;
    bottom: 14px;
    left: 14px;
  }
}

@media screen and (max-width: 448px) {
  .featuredItem {
    height: 100px;
  }

  .featuredItems {
    height: 100px;
  }

  h1{
    font-size: 1rem;
  }

  h2{
    font-size: 0.8rem;
  }
  .featuredTitles {
    position: absolute;
    bottom: 10px;
    left: 10px;
  }
}
@media screen and (max-width: 340px) {
  .featuredItem {
    height: 80px;
  }

  .featuredItems {
    height: 80px;
  }

  h1{
    font-size: 0.8rem;
  }

  h2{
    font-size: 0.6rem;
  }
}