
body {
  background-color: #f5f5f5;
}
.custom_container {
  width: 100%;
  max-width: 800px; /* Updated width for larger screens */
  margin: 50px auto;
  padding: 30px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}


.form_control {
  display: block;
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  background-color: #f5f5f5;
  margin-bottom: 20px;
}
.form_row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  flex-wrap: wrap; /* Added to wrap elements on smaller screens */
}
.form_row .form_group {
  width: 32%;
}
@media only screen and (max-width: 768px) {
  .form_row .form_group {
    width: 100%; /* Updated width for smaller screens */
  }
}
label {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
  display: block;
}

select {
  padding: 10px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  background-color: #f5f5f5;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  margin-bottom: 20px;
}

.btn {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
}
.btn:hover {
  background-color: #0069d9;
}
